* {
  box-sizing: border-box;
}

html, body {
  width: 100vw;
  height: 100vh;
}

body {
  background-color: #060606;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
}

a {
  color: #00bcd4;
  transition: all .1s;
}

a:hover {
  color: #80deea;
}

h1 {
  font-weight: normal;
}

.layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#bg {
  z-index: 10;
}

#container {
  z-index: 20;
  pointer-events: none;
  perspective: 500px;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

#container #panel {
  pointer-events: auto;
  color: #eee;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  max-width: 580px;
  background-color: rgba(255, 255, 255, .05);
  border: 1px solid rgba(255, 255, 255, .08);
  border-radius: .5rem;
  padding: 2rem;
  transition: all .2s ease-out;
  box-shadow: 0 0 30px rgba(0, 0, 0, .75);
}

/*# sourceMappingURL=index.d8319781.css.map */
