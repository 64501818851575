* {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #060606;
  font-family: sans-serif;
  line-height: 1.5;
  font-size: 1rem;
}

a {
  color: #00bcd4;
  transition: all 100ms;

  &:hover {
    color: #80deea;
  }
}

h1 {
  font-weight: normal;
}

.layer {
  position: absolute;
  inset: 0;
}

#bg {
  z-index: 10;
}

#container {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  padding: 2rem;
  perspective: 500px;

  #panel {
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.08);
    color: #eee;
    padding: 2rem;
    border-radius: 0.5rem;
    backdrop-filter: blur(10px);
    max-width: 580px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
    transition: all 0.2s ease-out;
  }
}
